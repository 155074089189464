import { setLocalStorageItem } from "../util/utils";
import { useEffect } from "react";
export default function AppStorage(props) {
  let {
    pageName = "",
    referralURL = "",
    choosenLanguageWeb = "En",
    isHipaaPage = false,
  } = props;
  // useEffect(() => {
  let data = {};
  if (typeof window !== "undefined") {
    let currentUrl = new URL(window.location.href);
    data.landingURL = currentUrl;
    data.utm_source = currentUrl.searchParams.get("utm_source");
    data.utm_medium = currentUrl.searchParams.get("utm_medium");
    data.utm_campaign = currentUrl.searchParams.get("utm_campaign");
    data.ref = currentUrl.searchParams.get("ref");
    data.gclid = currentUrl.searchParams.get("gclid");
  }

  if (typeof window !== "undefined") {
    setLocalStorageItem("pageName", pageName);
    setLocalStorageItem("referralURL", referralURL);
    setLocalStorageItem("landingURL", data?.landingURL);
    setLocalStorageItem("createdFrom", pageName);
    setLocalStorageItem("choosenLanguageWeb", choosenLanguageWeb);
    setLocalStorageItem("isHipaaPage", isHipaaPage);
    setLocalStorageItem("utm_source", data?.utm_source);
    setLocalStorageItem("utm_medium", data?.utm_medium);
    setLocalStorageItem("utm_campaign", data?.utm_campaign);
    setLocalStorageItem("ref", data?.ref);
    setLocalStorageItem("gclid", data?.gclid);
  }
  // });

  return <span></span>;
}

// Usage

{
  /* <AppStorage
  pageName=""
  referralURL=""
  landingURL=""
  createdFrom=""
  choosenLanguageWeb=""
  isHipaaPage=""
/>; */
}
